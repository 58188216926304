<template>
	<div class="relative main-h-screen flex">
		<div class="w-full">
			<div class="bg-gray-100 bg-white">
				<div class="flex text-sm justify-center">
					<div class="w-fill  bg-white shadow-md p-2">
						<nav class="flex mb-10" aria-label="Breadcrumb">
							<ol class="inline-flex items-center space-x-1 md:space-x-3">
								<li class="inline-flex items-center">
									<router-link
										:to="{ name: 'Home' }"
										class="inline-flex items-center text-sm text-gray-700 hover:text-gray-900 dark:text-gray-400 dark:hover:text-white"
									>
										<svg class="mr-2 w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
											<path
												d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"
											></path>
										</svg>
										Home
									</router-link>
								</li>

								<li aria-current="page">
									<div class="flex items-center">
										<svg class="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
											<path
												fill-rule="evenodd"
												d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
												clip-rule="evenodd"
											></path>
										</svg>
										<span class="ml-1 text-sm font-medium text-gray-400 md:ml-2 dark:text-gray-500">{{ title }}</span>
									</div>
								</li>
							</ol>
							<hr />
						</nav>
						<div class="space-y-10">
							<div class="space-y-2">
								<h2 class="text-2xl text-gray-700 font-semibold">{{ title }}</h2>
							</div>
							<hr />
							<!-- START HERE -->

							<article class="article-body" id="article-body" rel="image-enlarge">
								<p>
									Flex IPTV is available for Apple TV, iPhone, iPad.<br /><br /><strong>Flex IPTV</strong><br />Search FLEX IPTV in the App Store or download
									from&nbsp;
								</p>
								<p>
									<a href="https://apps.apple.com/ca/app/flex-iptv/id1182930255" rel="noreferrer">https://apps.apple.com/ca/app/flex-iptv/id1182930255</a
									><br /><br />Once installed click settings<br />Add playlist Remote<br /><br />
								</p>
								<div rel="clipboard_data">
									<p>Insert the required m3u and replace <strong>YOUR_</strong><strong>EMAIL/ PASSWORD</strong> with your details<br /><br /></p>
									<p>
										<strong>Channels list and recent VOD</strong><br /><a
											data-ft='{"tn":"-U"}'
											data-lynx-mode="asynclazy"
											:href="`${$store.state.brandInfo.tvLink}/api/list/YOUR_EMAIL/PASSWORD/m3u8/livetv`"
											rel="noreferrer noopener"
											target="_blank"
										></a
										><a
											data-fr-linked="true"
											dir="ltr"
											:href="`${$store.state.brandInfo.tvLink}/api/list/YOUR_EMAIL/PASSWORD/m3u8/tvshows/1`"
											style="box-sizing: border-box; color: rgb(8, 18, 29)"
										>
											{{ $store.state.brandInfo.tvLink }}</a
										>/api/list/<strong><span style="color: rgb(0, 0, 0)">YOUR_EMAIL</span></strong
										>/<strong><span style="color: rgb(0, 0, 0)">PASSWORD</span></strong
										><strong><span style="color: rgb(0, 0, 0)">/</span></strong
										>m3u8/livetv
									</p>
									<p>
										<br /><strong>Movies&nbsp;</strong><br /><a
											:href="`${$store.state.brandInfo.tvLink}/api/list/YOUR_EMAIL/PASSWORD/m3u8/movies`"
											rel="noreferrer noopener"
										></a
										><a
											data-fr-linked="true"
											dir="ltr"
											:href="`${$store.state.brandInfo.tvLink}/api/list/YOUR_EMAIL/PASSWORD/m3u8/tvshows/1`"
											style="box-sizing: border-box; color: rgb(8, 18, 29)"
										>
											{{ $store.state.brandInfo.tvLink }}</a
										>/api/list/<strong><span style="color: rgb(0, 0, 0)">YOUR_EMAIL</span></strong
										>/<strong><span style="color: rgb(0, 0, 0)">PASSWORD</span></strong
										>/m3u8/movies<br /><br /><strong>TV Shows</strong><br /><a
											:href="`${$store.state.brandInfo.tvLink}/api/list/YOUR_EMAIL/PASSWORD/m3u8/tvshows/1`"
										></a
										><a
											data-fr-linked="true"
											dir="ltr"
											:href="`${$store.state.brandInfo.tvLink}/api/list/YOUR_EMAIL/PASSWORD/m3u8/tvshows/1`"
											style="box-sizing: border-box; color: rgb(8, 18, 29)"
										>
											{{ $store.state.brandInfo.tvLink }}</a
										>/api/list/<span style="color: rgb(0, 0, 0)"><strong>YOUR_EMAIL</strong></span
										>/<span style="color: rgb(0, 0, 0)"><strong>PASSWORD</strong></span
										>/m3u8/tvshows/1
									</p>
									<p><br /></p>
									<p>After click ADD EPG and insert your required EPG (only one)</p>
									<p><br /></p>
									<p><strong>TV Guide/EPG URL</strong><br />Select Lite or Full url.&nbsp;</p>
									<p><br /></p>
									<p>Regular version – 7 day listing&nbsp;</p>
									<p>
										UTC 0: <a :href="$store.state.brandInfo.epgLink" rel="noreferrer noopener" target="_top"></a
										><a :href="`${$store.state.brandInfo.tvLink}/api/epg/YOUR_EMAIL/PASSWORD/full.xml.gz`" rel="noreferrer noopener"
											>{{ $store.state.brandInfo.tvLink }}/api/epg/<strong><span style="color: rgb(0, 0, 0)">YOUR_EMAIL</span></strong
											>/<strong><span style="color: rgb(0, 0, 0)">PASSWORD</span></strong
											>/full.xml.gz</a
										>
									</p>
									<p><br /></p>
									<p>Lite version - 48h listing (For old/small devices)</p>
									<p>
										UTC 0: <a :href="$store.state.brandInfo.epgLink" rel="noreferrer noopener" target="_top"></a
										><a :href="`${$store.state.brandInfo.tvLink}/api/epg/YOUR_EMAIL/PASSWORD/lite.xml.gz`" rel="noreferrer noopener"
											>{{ $store.state.brandInfo.tvLink }}/api/epg/<strong><span style="color: rgb(0, 0, 0)">YOUR_EMAIL</span></strong
											>/<strong><span style="color: rgb(0, 0, 0)">PASSWORD</span></strong
											>/lite.xml.gz</a
										>
									</p>
								</div>
								<p><br /></p>
							</article>

							<!-- FINISH HERE -->
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'flexIptv',
	data() {
		return {
			title: this.$route.meta.title
		};
	}
};
</script>

<style scoped></style>
